import {mapActions, mapGetters, mapMutations} from "vuex";
import Vue from 'vue'
import Vivus from 'vivus';
import {ObserveVisibility} from 'vue-observe-visibility'

Vue.directive('observe-visibility', ObserveVisibility)
import {maxLength, minLength, required, email, sameAs} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";


export default {
  name: "contacts",
  mixins: [validationMixin],
  components: {},
  data() {
    return {
      payload: {
        name: '',
        last_name: '',
        phone: '',
        email: '',
      },
      validationErrors: {},
    }
  },
  validations: {
    payload: {
      name: {
        required,
        maxLength: maxLength(50),
        minLength: minLength(2),
      },
      last_name: {
        required,
        maxLength: maxLength(50),
        minLength: minLength(2),
      },
      phone: {
        required,
        maxLength: maxLength(18),
        minLength: minLength(5),
      },
      email: {
        required,
        email,
        maxLength: maxLength(60),
      },
    }
  },
  created() {
  },
  computed: {
    ...mapGetters({
      feedbackLoading: 'home/feedbackLoading',
      variables: 'setting/variables'
    }),
    nameError() {
      const error = []
      if (!this.$v.payload.name.$dirty) {
        return error
      }
      if (!this.$v.payload.name.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.name.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 50))
      }
      if (!this.$v.payload.name.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 2))
      }
      if (this.validationErrors.name) {
        this.validationErrors.name.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    lastNameError() {
      const error = []
      if (!this.$v.payload.last_name.$dirty) {
        return error
      }
      if (!this.$v.payload.last_name.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.last_name.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 50))
      }
      if (!this.$v.payload.last_name.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 2))
      }
      if (this.validationErrors.last_name) {
        this.validationErrors.last_name.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    emailError() {
      const error = []
      if (!this.$v.payload.email.$dirty) {
        return error
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('errorEmail'))
      }
      if (!this.$v.payload.email.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 50))
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    phoneError() {
      const error = []
      if (!this.$v.payload.phone.$dirty) {
        return error
      }
      if (!this.$v.payload.phone.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.phone.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 18))
      }
      if (!this.$v.payload.phone.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 5))
      }
      if (this.validationErrors.phone) {
        this.validationErrors.phone.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },

  },
  methods: {
    ...mapActions({
      sendFeedback: 'home/SEND_FEEDBACK'
    }),
    visibilityChanged(isVisible, entry) {
      this.isVisible = isVisible
      if (entry.isIntersecting === true) {
        new Vivus('contact', {duration: 200});
      }
    },
    getVariable(key) {
      return this.variables ? this.variables.find(el => el.key === key) ? this.variables.find(el => el.key === key).value : null : null
    },
    submitForm(){
      this.$v.payload.$touch()
      if (!this.$v.payload.$invalid) {
        this.sendFeedback(this.payload).then(()=>{
          this.$toasted.success('Message sent!')
          this.resetForm()
        }).catch(error => {
          if (error.status === 403) {
            this.$toasted.error(error.data.message)
          }
          if (error.status === 422) {
            this.$toasted.error(error.data.message)
          }
          if (error.status === 418) {
            this.$toasted.error(error.data.message)
          }
        })
      }
    },
    resetForm(){
      this.payload.name = ''
      this.payload.last_name = ''
      this.payload.phone = ''
      this.payload.email = ''
      this.$v.payload.$reset()
    }
  }
}